import PropTypes from "prop-types";

const SectionIcon = ({ src, color}) => {
    return (
        <div
            style={{
                width: "45px",
                height: "45px",
                padding: "10px 5px 5px",
                backgroundColor: color,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden"
            }}
            className="me-3 py-2 px-1"
        >
            <img
                style={{width: "90%"}}
                src={src}
                alt="section next icon"
            />
        </div>
    )
};

SectionIcon.propTypes = {
    src: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
};

export default SectionIcon;
