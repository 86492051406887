import {useState} from "react";

import {App, Divider, theme, Typography} from "antd";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

import FormField from "../../../components/form/FormField.jsx";
import Input from "../../../components/form/Input.jsx";
import {setIsAuthenticated} from "../../../redux/users/reducer.js";
import {LocalStorageName} from "../../../utilities/constants.jsx";
import {handleErrors} from "../../../utilities/index.js";
import PrimaryButton from "../../../components/PrimaryButton.jsx";
import DeleteConfirmationModel from "../../../components/DeleteConfirmationModel.jsx";
import RetrieveData from "./RetrieveData.jsx";
import {
    useDeleteCurrentUserMutation,
    useGetCurrentUserQuery,
    useUpdateCurrentUserMutation
} from "../../../redux/service.js";

const { Title, Text } = Typography;

const ChangePasswordForm = () => {
  const { token } = theme.useToken();
  const [changePassword, { isLoading }] = useUpdateCurrentUserMutation();
  const { notification } = App.useApp();
  const { t } = useTranslation();

  const changePasswordSchema = yup.object().shape({
    oldPassword: yup
      .string()
      .typeError(t("form.validation.oldPasswordNotValid"))
      .required(t("form.validation.oldPasswordRequired")),
    newPassword: yup
      .string()
      .typeError(t("form.validation.newPasswordNotValid"))
      .min(8, t("form.validation.passwordLengthValidation"))
      .matches(/[A-Z]/, t("form.validation.passwordCapitalLetterValidation"))
      .matches(/[0-9]/, t("form.validation.passwordNumberValidation"))
      .required(t("form.validation.newPasswordRequired")),
    confirmPassword: yup
      .string()
      .typeError(t("form.validation.passwordNotValid"))
      .oneOf(
        [yup.ref("newPassword"), null],
        t("form.validation.passwordsMustMatch"),
      )
      .required(t("form.validation.passwordRequired")),
  });

  const form = useForm({
    resolver: yupResolver(changePasswordSchema),
    mode: "onChange",
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const onSubmit = (values) => {
    changePassword(values)
      .unwrap()
      .then(() => {
        notification.success({
          message: t("messages.passwordChange.successMessage"),
        });
        form.reset();
      })
      .catch((errors) => handleErrors(errors, form.setError, notification));
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Title level={4} style={{ color: token.colorPrimaryText }}>
        {t("titles.changePassword")}
      </Title>
      <FormField
        label={t("form.labels.oldPassword")}
        field={
          <Input
            name="oldPassword"
            htmlType="password"
            control={form.control}
          />
        }
      />
      <FormField
        label={t("form.labels.newPassword")}
        field={
          <Input
            name="newPassword"
            htmlType="password"
            control={form.control}
          />
        }
      />
      <FormField
        label={t("form.labels.confirmPassword")}
        field={
          <Input
            name="confirmPassword"
            htmlType="password"
            control={form.control}
          />
        }
      />
      <PrimaryButton htmlType="submit" disabled={isLoading} className="mt-3">
        {t("buttons.updatePassword")}
      </PrimaryButton>
    </form>
  );
};


const ChangeEmailForm = () => {
  const { token } = theme.useToken();
  const { notification } = App.useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [changeEmail, { isLoading }] = useUpdateCurrentUserMutation();

  const changeEmailSchema = yup.object().shape({
    newEmail: yup
      .string()
      .typeError(t("form.validation.emailNotValid"))
      .email(t("form.validation.emailNotValid"))
      .required(t("form.validation.emailRequired")),
    password: yup
      .string()
      .typeError(t("form.validation.passwordNotValid"))
      .min(8, t("form.validation.passwordLengthValidation"))
      .matches(/[A-Z]/, t("form.validation.passwordCapitalLetterValidation"))
      .matches(/[0-9]/, t("form.validation.passwordNumberValidation"))
      .required(t("form.validation.passwordRequired")),
  });

  const form = useForm({
    resolver: yupResolver(changeEmailSchema),
    mode: "onTouched",
  });

  const onSubmit = (values) => {
    changeEmail(values)
      .unwrap()
      .then(() => {
        notification.success({
          message: t("messages.emailChangedAndVerificationEmail"),
        });
        dispatch(setIsAuthenticated({ isAuthenticated: false }));
        window.localStorage.removeItem(LocalStorageName);
        navigate("/login");
      })
      .catch((errors) => handleErrors(errors, form.setError, notification));
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Title level={4} style={{ color: token.colorPrimaryText }}>
        Change email
      </Title>
      <FormField
        label={t("form.labels.password")}
        field={
          <Input name="password" htmlType="password" control={form.control} />
        }
      />
      <FormField
        label={t("form.labels.newEmail")}
        field={
          <Input name="newEmail" htmlType="email" control={form.control} />
        }
      />
      <PrimaryButton htmlType="submit" disabled={isLoading} className="mt-3">
        {t("buttons.updateEmail")}
      </PrimaryButton>
    </form>
  );
};

const AccountSettings = () => {
  const { notification } = App.useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const deleteUserSchema = yup.object().shape({
    password: yup
      .string()
      .typeError(t("form.validation.passwordNotValid"))
      .min(8, t("form.validation.passwordLengthValidation"))
      .matches(/[A-Z]/, t("form.validation.passwordCapitalLetterValidation"))
      .matches(/[0-9]/, t("form.validation.passwordNumberValidation"))
      .required(t("form.validation.passwordRequired")),
  });


  const form = useForm({
    resolver: yupResolver(deleteUserSchema),
    mode: "onChange",
  });
  const [deleteUser, { isLoading }] = useDeleteCurrentUserMutation();
  const { data: currentUser } = useGetCurrentUserQuery();
  const [open, setOpen] = useState(false);

  const handleDelete = async () => {
    await form.trigger();
    deleteUser(form.getValues())
      .unwrap()
      .then(() => {
        notification.success({ message: t("accountDeleted") });
        dispatch(setIsAuthenticated({ isAuthenticated: false }));
        window.localStorage.removeItem(LocalStorageName);
        navigate("/login");
      })
      .catch((errors) => handleErrors(errors, form.setError, notification));
  };

    return (
        <div>
            <div>
                <ChangePasswordForm/>
                <Divider/>
            </div>
            <div>
                <ChangeEmailForm/>
                <Divider/>
            </div>
            <div>
                <Title level={4}>{t("deleteAccount")}</Title>
                <PrimaryButton className="mt-2" onClick={() => setOpen(true)}>
                    {t("buttons.deleteAccount")}
                </PrimaryButton>
                <DeleteConfirmationModel
                    isLoading={isLoading}
                    onSubmit={handleDelete}
                    title={t("confirm.deleteUserConfirmationTitle")}
                    onCancel={() => setOpen(false)}
                    open={open}
                >
                    <Text>{t("confirm.deleteUserConfirmationContent1")}</Text>
                    <div>
                      <FormField
                          label={t("form.labels.password")}
                          field={
                            <Input
                              name="password"
                              htmlType="password"
                              control={form.control}
                            />
                          }
                        />
                    </div>
                </DeleteConfirmationModel>
            </div>
            <div className="mt-3">
                {currentUser && <RetrieveData userId={currentUser.id}/>}
            </div>
        </div>
    )
};

export default AccountSettings;
