import { useMemo } from "react";

import { Collapse, Typography } from "antd";
import PropTypes from "prop-types";

import {
  OptionsOrientation,
  OptionType,
} from "../../../../utilities/constants.jsx";
import Option from "./Option.jsx";
import { useGetQuestionByIdQuery } from "../../../../redux/service.js";

const { Title } = Typography;

const Question = ({ section, questionId }) => {
  const { data: question, isSuccess } = useGetQuestionByIdQuery({ questionId });

  const isQuestionAnswered = useMemo(() => {
    if (!isSuccess) return false;
    const answers = question.options.filter((i) => i.answer);
    return (
      answers.length > 0 && answers.map((i) => i.requiredObjectsCount === 0 && i.requiredNarrativesCount === 0).every(Boolean)
    );
  }, [question, isSuccess]);

  const getClassesForOption = (index, areOptionsHorizontal) => {
    let classes = [];
    if (index !== 0 && !areOptionsHorizontal) {
      classes.push("mt-4");
    }
    if (areOptionsHorizontal) {
      classes.push("ms-3");
    }

    return classes.join(" ");
  };

  const options = useMemo(() => {
    if (!isSuccess) return;

    const isSingleChoice =
      question.options.length > 0
        ? question.options[0].type === OptionType.SINGLE_CHOICE.value
        : null;

    const singleChoiceAnswerId = isSingleChoice
      ? question.options.find((i) => i.answer !== null)?.answer
      : null;
    const areOptionsHorizontal =
      question.optionsOrientation === OptionsOrientation.HORIZONTAL.value;

    const options = question.options
      .slice()
      .sort((a, b) => a.id - b.id)
      .map((option, index) => (
        <div
          className={getClassesForOption(index, areOptionsHorizontal)}
          style={{ display: "flex" }}
          key={option.id}
        >
          {question.isRanked && (
            <div className="pt-2 me-3">
              <Title level={5}>{index + 1}</Title>
            </div>
          )}
          <div style={{ width: "100%" }}>
            <Option
              answerId={singleChoiceAnswerId || option.answer}
              option={option}
              key={option.id}
            />
          </div>
        </div>
      ));

    return options;
  }, [question, isSuccess]);

  const adjustBackgroundColor = (color, opacity = 0.4) => {
    if (color.startsWith("#")) {
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    } else if (color.startsWith("rgb")) {
      const rgbValues = color.match(/\d+/g);
      const r = parseInt(rgbValues[0]);
      const g = parseInt(rgbValues[1]);
      const b = parseInt(rgbValues[2]);
      if (rgbValues.length === 4) {
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
      } else {
        return `rgba(${r}, ${g}, ${b}, 0.4)`;
      }
    } else {
      return color;
    }
  };

  return (
    isSuccess && (
      <div>
        <Collapse
          className="mt-3"
          expandIconPosition="end"
          defaultActiveKey={[questionId]}
          style={{
            backgroundColor: isQuestionAnswered
              ? section.color === ""
                ? "rgba(231, 238, 224, 0.4)"
                : adjustBackgroundColor(section.color)
              : "white",
            boxShadow: "0px 2px 3px -2px rgba(0, 0, 61, 0.051)",
            borderLeft: isQuestionAnswered
              ? section.color === ""
                ? "7px solid rgba(231, 238, 224, 1)"
                : `7px solid ${adjustBackgroundColor(section.color, 1)}`
              : "",
          }}
          items={[
            {
              key: question.id,
              label: (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Title level={4} style={{ fontWeight: "bold" }}>
                    {question.index + 1}
                  </Title>
                  <Title className="ms-2" level={5}>
                    {question.content}
                  </Title>
                </div>
              ),
              isActive: true,
              children: (
                <div
                  style={{
                    display:
                      question.optionsOrientation ===
                      OptionsOrientation.HORIZONTAL.value
                        ? "flex"
                        : "block",
                  }}
                >
                  {options}
                </div>
              ),
            },
          ]}
        />
      </div>
    )
  );
};

Question.propTypes = {
  questionId: PropTypes.number.isRequired,
  section: PropTypes.object.isRequired,
};

export default Question;
