import { useState } from "react";

import SectionModalForm from "./SectionModalForm.jsx";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Collapse } from "antd";

import PrimaryButton from "../../../../../components/PrimaryButton.jsx";
import {
  useDeleteSectionMutation,
  useGetSectionsQuery,
} from "../../../../../redux/service.js";
import SectionVerticalBar from "../../SectionVerticalBar.jsx";
import QuestionForm from "../questions/QuestionForm.jsx";

const SectionForm = ({ surveyId }) => {
  const { t } = useTranslation();

  const { data: sections, isSuccess } = useGetSectionsQuery({
    survey: surveyId,
    pagination: "off",
  });
  const [deleteSection] = useDeleteSectionMutation();

  const [sectionModal, setSectionModal] = useState({
    open: false,
    data: null,
  });

  return (
    <div>
      <PrimaryButton
        className="mt-3"
        onClick={() => setSectionModal({ open: true, data: null })}
      >
          {t("createSection")}
      </PrimaryButton>
      <SectionModalForm
        onCancel={() => setSectionModal({ open: false, data: null })}
        surveyId={surveyId}
        {...sectionModal}
      />
      {isSuccess &&
        sections
          .slice()
          .sort((a, b) => a.index - b.index)
          .map((section) => (
            <div key={section.id} className="mt-4">
              <Collapse
                style={{
                  width: "90%",
                  border: "1px solid rgba(198, 206, 220, 1)",
                }}
                expandIconPosition="end"
                className="mt-3 mb-4"
                items={[
                  {
                    key: "1",
                    label: (
                      <div>
                        <SectionVerticalBar section={section} />
                        <div style={{ textAlign: "right", marginTop: "-20px" }}>
                          <PrimaryButton
                            type="secondary"
                            className="me-3"
                            onClick={() =>
                              setSectionModal({ open: true, data: section })
                            }
                          >
                            {t("buttons.edit")}
                          </PrimaryButton>
                          <PrimaryButton
                            type="secondary"
                            onClick={() => deleteSection(section.id)}
                          >
                            {t("buttons.delete")}
                          </PrimaryButton>
                        </div>
                      </div>
                    ),
                    children: <QuestionForm sectionId={section.id} />,
                  },
                ]}
              />
            </div>
          ))}
    </div>
  );
};

SectionForm.propTypes = {
  surveyId: PropTypes.string.isRequired,
};

export default SectionForm;
