import { useNavigate } from "react-router-dom";
import { Progress, Typography } from "antd";
import PropTypes from "prop-types";

const { Text } = Typography;

const SectionProgressCircle = ({
  requiredQuestionsCount,
  answeredQuestionsCount,
  color,
}) => {
  if (answeredQuestionsCount === 0) {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 0.25C3.71875 0.25 0.25 3.71875 0.25 8C0.25 12.2812 3.71875 15.75 8 15.75C12.2812 15.75 15.75 12.2812 15.75 8C15.75 3.71875 12.2812 0.25 8 0.25ZM8 14.25C4.54688 14.25 1.75 11.4531 1.75 8C1.75 4.54688 4.54688 1.75 8 1.75C11.4531 1.75 14.25 4.54688 14.25 8C14.25 11.4531 11.4531 14.25 8 14.25Z"
          fill="#C6CEDC"
        />
      </svg>
    );
  } else if (answeredQuestionsCount >= requiredQuestionsCount) {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.75 8C15.75 12.2802 12.2802 15.75 8 15.75C3.71978 15.75 0.25 12.2802 0.25 8C0.25 3.71978 3.71978 0.25 8 0.25C12.2802 0.25 15.75 3.71978 15.75 8ZM7.10356 12.1036L12.8536 6.35356C13.0488 6.15831 13.0488 5.84172 12.8536 5.64647L12.1465 4.93937C11.9512 4.74409 11.6346 4.74409 11.4393 4.93937L6.75 9.62869L4.56066 7.43934C4.36541 7.24409 4.04881 7.24409 3.85353 7.43934L3.14644 8.14644C2.95119 8.34169 2.95119 8.65828 3.14644 8.85353L6.39644 12.1035C6.59172 12.2988 6.90828 12.2988 7.10356 12.1036Z"
          fill="#7E808A"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 0.25C3.71978 0.25 0.25 3.71978 0.25 8C0.25 12.2802 3.71978 15.75 8 15.75C12.2802 15.75 15.75 12.2802 15.75 8C15.75 3.71978 12.2802 0.25 8 0.25ZM10.5 8C10.5 9.3785 9.3785 10.5 8 10.5C6.6215 10.5 5.5 9.3785 5.5 8C5.5 6.6215 6.6215 5.5 8 5.5C9.3785 5.5 10.5 6.6215 10.5 8Z"
          fill={color}
        />
      </svg>
    );
  }
};

SectionProgressCircle.propTypes = {
  requiredQuestionsCount: PropTypes.number.isRequired,
  answeredQuestionsCount: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

const SectionProgressBar = ({ sections }) => {
  const navigate = useNavigate();

  const calculatePercentage = (section) => {
    return section.requiredQuestionsCount === 0
      ? 100
      : Math.min(
          section.answeredQuestionsCount / section.requiredQuestionsCount,
          1,
        ) * 100;
  };

  return (
    <div style={{ display: "flex" }}>
      {sections
        .slice()
        .sort((a, b) => a.index - b.index)
        .map((section) => {
          const percentage = calculatePercentage(section);

          return (
            <div
              key={section.id}
              style={{ cursor: "pointer", flex: 1 }}
              className="ms-2"
              onClick={() =>
                navigate(
                  `/reports/${section.survey}/section/${section.id}`,
                )
              }
            >
              <Progress
                percent={percentage}
                className="section-progress"
                size="small"
                strokeColor={
                  percentage === 100
                    ? "rgba(126, 128, 138, 1)"
                    : section.color
                }
                style={{ marginBottom: "0px" }}
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                <SectionProgressCircle
                  requiredQuestionsCount={section.requiredQuestionsCount}
                  answeredQuestionsCount={section.answeredQuestionsCount}
                  color={section.color}
                />
                <Text
                  className="ms-2"
                  style={{
                    color:
                      percentage === 100
                        ? "rgba(60, 66, 87, 1)"
                        : "rgba(79, 86, 107, 1)",
                  }}
                >
                  {section.title}
                </Text>
              </div>
            </div>
          );
        })}
    </div>
  );
};

SectionProgressBar.propTypes = {
  sections: PropTypes.array.isRequired,
};

export default SectionProgressBar;
