import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { App, Col, Row } from "antd";
import { useForm } from "react-hook-form";

import {
  useDeleteSurveyMutation,
  useGetSurveyByIdQuery,
  usePatchSurveyMutation,
} from "../../../../redux/service.js";
import SectionForm from "./sections/SectionForm.jsx";
import PrimaryButton from "../../../../components/PrimaryButton.jsx";
import SurveyDetails from "./SurveyDetails.jsx";
import Select from "../../../../components/form/Select.jsx";
import { SurveyStatus } from "../../../../utilities/constants.jsx";
import Checkbox from "../../../../components/form/Checkbox.jsx";
import { handleErrors } from "../../../../utilities/index.js";

const defaultValues = {
  status: null,
  automaticPublish: null,
};

const SurveyForm = () => {
  const { surveyId } = useParams();
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const { data: survey, isSuccess } = useGetSurveyByIdQuery({ surveyId });
  const [deleteSurvey] = useDeleteSurveyMutation();
  const [patchSurvey] = usePatchSurveyMutation();
  const [isFormTouched, setIsFormTouched] = useState(false);
  const form = useForm({
    mode: "onChange",
    defaultValues,
  });

  const onDeleteSurvey = () => {
    deleteSurvey(surveyId)
      .unwrap()
      .then(() => notification.success({ message: t("surveyDeleted") }));
  };

  useEffect(() => {
    if (survey && !isFormTouched) {
      Object.keys(survey)
        .filter((key) => Object.keys(defaultValues).includes(key))
        .map((key) => form.setValue(key, survey[key]));
      setIsFormTouched(true);
    }
  }, [survey]);

  useEffect(() => {
    const subscription = form.watch((data) => {
      if (isFormTouched) {
        patchSurvey({ id: surveyId, data }).catch((errors) =>
          handleErrors(errors, form.setError, notification),
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [isFormTouched, form.watch]);

  return (
    isSuccess && (
      <div>
        <Row style={{ textAlign: "right", justifyContent: "end" }}>
          <Col xl={7} lg={7} sm={24}>
            <Checkbox
              name="automaticPublish"
              label={t("form.placeholders.automaticPublish")}
              control={form.control}
              defaultValue={survey.automaticPublish}
            />
          </Col>
          <Col xl={4} lg={4} sm={24}>
            <Select
              name="status"
              options={SurveyStatus.asList()}
              placeholder={t("form.placeholders.surveyStatus")}
              control={form.control}
            />
          </Col>
          <Col xl={2} lg={2} sm={24}>
            <PrimaryButton className="mt-2" onClick={onDeleteSurvey}>
              {t("buttons.delete")}
            </PrimaryButton>
          </Col>
        </Row>
        <SurveyDetails survey={survey} />
        <SectionForm surveyId={surveyId} />
      </div>
    )
  );
};

export default SurveyForm;
