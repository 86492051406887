import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { App, Checkbox, Col, Modal, Row } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

import FormField from "../../../../../components/form/FormField.jsx";
import {
  useCreateQuestionMutation,
  useDeleteQuestionMutation,
  useGetQuestionsQuery,
  useUpdateQuestionMutation,
} from "../../../../../redux/service.js";
import DebouncedInput from "../../../../../components/form/DebouncedInput.jsx";
import { handleErrors } from "../../../../../utilities/index.js";
import Select from "../../../../../components/form/Select.jsx";
import {
  ObjectTypes,
  OptionType,
} from "../../../../../utilities/constants.jsx";
import PrimaryButton from "../../../../../components/PrimaryButton.jsx";
import {
  RequireNarrativeModal,
  RequireObjectModal,
} from "./OptionEntityModals.jsx";

const optionCreateSchema = (t) =>
  yup.object().shape({
    label: yup
      .string()
      .typeError(t("form.validation.labelNotValid"))
      .required(t("form.validation.labelRequired")),
  });

const OptionForm = ({ onSubmit, initialData, onDelete, questionType }) => {
  const { t } = useTranslation();

  const [openObjectModal, setOpenObjectModal] = useState({
    open: false,
    data: null,
  });
  const [openNarrativeModal, setOpenNarrativeModal] = useState({
    open: false,
    data: null,
  });
  const form = useForm({
    resolver: yupResolver(optionCreateSchema(t)),
    mode: "onChange",
  });

  const handleDeleteOptionEntity = (optionEntityId) => {
    const optionEntities = form.getValues()?.optionEntities || [];
    onSubmit({
      ...initialData,
      optionEntities: optionEntities.filter((i) => i.id !== optionEntityId),
    });
  };

  useEffect(() => {
    const subscription = form.watch(async (data) => {
      const isFormValid = await form.trigger();
      if (isFormValid) {
        onSubmit({ ...initialData, ...data });
      }
    });
    return () => subscription.unsubscribe();
  }, [form.watch]);

  return (
    <div
      style={{ border: "1px solid rgba(1, 6, 47, 0.173)" }}
      className="mb-3 py-2 pb-4 px-4"
    >
      <Row gutter={40}>
        {![OptionType.OBJECT.value, OptionType.NARRATIVE.value].includes(
          questionType,
        ) && (
          <Col xl={16} md={23} sm={24}>
            <FormField
              label={t("form.labels.label")}
              required
              field={
                <DebouncedInput
                  initialValue={initialData?.label || ""}
                  onChange={(value) => form.setValue("label", value)}
                  rows={2}
                />
              }
            />
          </Col>
        )}
        {questionType !== OptionType.NARRATIVE.value && (
          <Col xl={3} md={3} sm={24}>
            <PrimaryButton
              disabled={!initialData?.id}
              onClick={() => setOpenObjectModal({ open: true, data: null })}
            >
              {t("form.labels.requireObject")}
            </PrimaryButton>
          </Col>
        )}
        {questionType !== OptionType.OBJECT.value && (
          <Col xl={3} md={3} sm={24}>
            <PrimaryButton
              disabled={!initialData?.id}
              onClick={() => setOpenNarrativeModal({ open: true, data: null })}
            >
              {t("form.labels.requireNarrative")}
            </PrimaryButton>
          </Col>
        )}
        <Col xl={2} md={2} sm={24} style={{ textAlign: "center" }}>
          <CloseOutlined onClick={() => onDelete(initialData?.id)} />
        </Col>
      </Row>
      <div>
        {(initialData?.optionEntities || []).map((optionEntity) => (
          <Row
            key={optionEntity.id}
            className="mt-4 py-2 px-3"
            style={{
              backgroundColor: "rgba(0, 0, 48, 0.106)",
              display: "flex",
            }}
          >
            <Col xl={18} md={18} sm={24}>
              {OptionType.getItemByValue(optionEntity.type).label}{" "}
              {optionEntity.type === OptionType.OBJECT.value &&
                `- ${ObjectTypes.getItemByValue(optionEntity.objectType)?.label}`}
            </Col>
            <Col xl={3} md={3} sm={24}>
              <PrimaryButton
                type="secondary"
                onClick={() => {
                  if (optionEntity.type === OptionType.OBJECT.value) {
                    setOpenObjectModal({ open: true, data: optionEntity });
                  } else {
                    setOpenNarrativeModal({ open: true, data: optionEntity });
                  }
                }}
              >
                {t("buttons.edit")}
              </PrimaryButton>
            </Col>
            <Col xl={3} md={3} sm={24}>
              <PrimaryButton
                type="secondary"
                onClick={() => handleDeleteOptionEntity(optionEntity.id)}
              >
                {t("buttons.delete")}
              </PrimaryButton>
            </Col>
          </Row>
        ))}
      </div>
      {initialData?.id && (
        <RequireObjectModal
          open={openObjectModal.open}
          initialData={openObjectModal.data}
          onCancel={() => setOpenObjectModal({ open: false, data: null })}
          optionId={initialData.id}
        />
      )}
      {initialData?.id && (
        <RequireNarrativeModal
          open={openNarrativeModal.open}
          initialData={openNarrativeModal.data}
          onCancel={() => setOpenNarrativeModal({ open: false, data: null })}
          optionId={initialData.id}
        />
      )}
    </div>
  );
};

OptionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.object,
  onDelete: PropTypes.func,
  questionType: PropTypes.string,
};

OptionForm.defaultProps = {
  initialData: null,
  questionType: null,
};

const questionCreateSchema = (t) =>
  yup.object().shape({
    content: yup
      .string()
      .typeError(t("form.validation.questionContentNotValid"))
      .required(t("form.validation.questionContentRequired")),
    options: yup.array().of(optionCreateSchema(t)),
    type: yup
      .string()
      .typeError(t("form.validation.typeNotValid"))
      .required(t("form.validation.typeRequired")),
  });

const DeleteQuestionModal = ({ open, onCancel, questionId }) => {
  const { t } = useTranslation();
  const [deleteQuestion] = useDeleteQuestionMutation();

  return (
    <Modal
      open={open}
      title={t("deleteQuestionTitle")}
      onCancel={onCancel}
      footer={[
        <PrimaryButton key="cancel" onClick={onCancel}>
          {t("buttons.cancel")}
        </PrimaryButton>,
        <PrimaryButton key="confirm" onClick={() => deleteQuestion(questionId)}>
          {t("buttons.confirm")}
        </PrimaryButton>,
      ]}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      {t("confirmDeleteQuestion")}
    </Modal>
  );
};

DeleteQuestionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  questionId: PropTypes.number.isRequired,
};

const SingleQuestionForm = ({ sectionId, question, onCreated }) => {
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const [openDeleteQuestionModal, setOpenDeleteQuestionModal] = useState(false);
  const [showNewOptionForm, setShowNewOptionForm] = useState(false);
  const [showAddNewOptionForm, setShowAddNewOptionForm] = useState(false);
  const [showAdditionalTextOption, setShowAdditionalTextOption] =
    useState(false);
  const [createQuestion] = useCreateQuestionMutation();
  const [updateQuestion] = useUpdateQuestionMutation();
  const form = useForm({
    resolver: yupResolver(questionCreateSchema(t)),
    mode: "onChange",
    defaultValues: {
      content: question?.content || "",
      type:
        (question?.options || []).length > 0 ? question?.options[0].type : "",
      options: question?.options || [],
    },
  });
  const questionType = form.watch("type");

  const formatOptionData = (option) => {
    const optionData = {
      question: question?.id,
      ...option,
      type: option?.type || form.getValues()?.type,
    };

    return optionData;
  };

  const getOptionsForType = (currentOptions) => {
    if (currentOptions) return currentOptions;

    if (
      [OptionType.OBJECT.value, OptionType.NARRATIVE.value].includes(
        questionType,
      )
    ) {
      return [{ label: question.content, type: questionType }];
    } else {
      return [];
    }
  };

  const handleQuestion = async (values) => {
    const isFormValid = await form.trigger();
    if (!isFormValid) return;

    const previousQuestionType =
      (question?.options || []).length > 0 ? question?.options[0].type : "";
    const currentOptions =
      previousQuestionType !== questionType ? [] : values?.options;
    const options = getOptionsForType(currentOptions).map(formatOptionData);

    if (question?.id) {
      updateQuestion({
        id: question.id,
        data: {
          section: sectionId,
          ...values,
          options,
        },
      })
        .unwrap()
        .then((data) => {
          if (
            [
              OptionType.TEXT.value,
              OptionType.OBJECT.value,
              OptionType.NARRATIVE.value,
            ].includes(questionType)
          ) {
            setShowNewOptionForm(data.options.length === 1);
          } else {
            setShowNewOptionForm(false);
          }
        })
        .catch((errors) => handleErrors(errors, form.setError, notification));
    } else {
      createQuestion({ ...values, options, section: sectionId })
        .unwrap()
        .then(onCreated)
        .catch((errors) => handleErrors(errors, form.setError, notification));
    }
  };

  useEffect(() => {
    const subscription = form.watch((data) => handleQuestion(data));
    return () => subscription.unsubscribe();
  }, [form.watch]);

  useEffect(() => {
    setShowAdditionalTextOption(
      (question?.options || []).filter(
        (i) => i.type === OptionType.CHOICE_TEXT.value,
      ).length === 1,
    );
  }, [question]);

  useEffect(() => {
    if (question.type !== questionType) {
      if (
        [
          OptionType.TEXT.value,
          OptionType.OBJECT.value,
          OptionType.NARRATIVE.value,
        ].includes(questionType)
      ) {
        const options = form.getValues()?.options || [];
        setShowNewOptionForm(options.length === 0);
        setShowAddNewOptionForm(false);
        setShowAdditionalTextOption(false);
      } else {
        setShowAddNewOptionForm(true);
      }
    }
  }, [questionType]);

  const handleOptionSubmit = (data) => {
    const options = form.getValues()?.options || [];
    if (data?.id) {
      form.setValue(
        "options",
        options.map((option) => {
          if (option.id === data.id) {
            return formatOptionData(option);
          }
          return option;
        }),
      );
    } else {
      form.setValue("options", [...options, formatOptionData(data)]);
    }
  };

  const handleDeleteQuestion = () => {
    if (question) {
      setOpenDeleteQuestionModal(true);
    } else {
      onCreated();
    }
  };

  const handleRemoveOption = (optionId) => {
    if (
      ![
        OptionType.TEXT.value,
        OptionType.OBJECT.value,
        OptionType.NARRATIVE.value,
      ].includes(questionType)
    ) {
      if (optionId) {
        const options = form.getValues()?.options || [];
        form.setValue(
          "options",
          options.filter((i) => i.id !== optionId),
        );
      } else {
        setShowNewOptionForm(false);
      }
    }
  };

  const handleAdditionalTextOption = (data) => {
    const options = form.getValues()?.options || [];
    const newOption = {
      ...formatOptionData(data),
      type: OptionType.CHOICE_TEXT.value,
    };
    if (data?.id) {
      form.setValue(
        "options",
        options.map((option) => {
          if (option.id === data.id) {
            return newOption;
          }
          return option;
        }),
      );
    } else {
      form.setValue("options", [...options, newOption]);
    }
  };

  const handleAdditionalTextOptionCheck = (checked) => {
    if (!checked) {
      const options = form.getValues()?.options || [];
      form.setValue(
        "options",
        options.filter(
          (option) => option.type !== OptionType.CHOICE_TEXT.value,
        ),
      );
    }
    setShowAdditionalTextOption(checked);
  };

  return (
    <div
      style={{ border: "1px solid rgba(198, 206, 220, 1)" }}
      className="pt-2 pb-3 px-3"
    >
      <Row gutter={40}>
        <Col xl={17} md={17} sm={24}>
          <FormField
            label={t("form.labels.title")}
            required
            field={
              <DebouncedInput
                initialValue={question?.content || ""}
                onChange={(value) => form.setValue("content", value)}
                errors={form.formState.errors?.content}
                rows={2}
              />
            }
          />
        </Col>
        <Col xl={6} md={6} sm={23}>
          <FormField
            label={t("form.labels.type")}
            required
            field={
              <Select
                name="type"
                placeholder={t("form.placeholders.selectType")}
                options={OptionType.asList().filter(
                  (i) => i.value !== OptionType.CHOICE_TEXT.value,
                )}
                control={form.control}
              />
            }
          />
          {[
            OptionType.MULTIPLE_CHOICE.value,
            OptionType.SINGLE_CHOICE.value,
          ].includes(questionType) && (
            <Checkbox
              onChange={(e) =>
                handleAdditionalTextOptionCheck(e.target.checked)
              }
              checked={showAdditionalTextOption}
            >
              {t("buttons.addAdditionalTextInput")}
            </Checkbox>
          )}
        </Col>
        <Col xl={1} md={1} sm={1}>
          <CloseOutlined onClick={handleDeleteQuestion} />
        </Col>
      </Row>
      <div className="mt-3 py-2 px-3">
        {(question?.options || [])
          .filter((i) => i.type !== OptionType.CHOICE_TEXT.value)
          .sort((a, b) => a.index - b.index)
          .map((option) => (
            <OptionForm
              key={option.id}
              questionType={questionType}
              onSubmit={handleOptionSubmit}
              initialData={{
                ...option,
                label: [
                  OptionType.NARRATIVE.value,
                  OptionType.OBJECT.value,
                ].includes(questionType)
                  ? question?.content
                  : option?.label,
              }}
              onDelete={handleRemoveOption}
            />
          ))}
        {showNewOptionForm && (
          <OptionForm
            questionType={questionType}
            initialData={
              [OptionType.NARRATIVE.value, OptionType.OBJECT.value].includes(
                questionType,
              )
                ? { label: question?.content }
                : null
            }
            onSubmit={handleOptionSubmit}
            onDelete={handleRemoveOption}
          />
        )}
        {showAdditionalTextOption && (
          <div>
            {t("form.labels.additionalTextOption")}
            <OptionForm
              questionType={questionType}
              initialData={(question?.options || []).find(
                (i) => i.type === OptionType.CHOICE_TEXT.value,
              )}
              onSubmit={handleAdditionalTextOption}
              onDelete={() => setShowAdditionalTextOption(false)}
            />
          </div>
        )}
      </div>
      {showAddNewOptionForm && (
        <div
          style={{ background: "rgba(233, 240, 252, 1)" }}
          onClick={() => setShowNewOptionForm(true)}
          className="mt-3 px-3 py-2"
        >
          <PlusOutlined /> <a>{t("addOption")}</a>
        </div>
      )}
      {question && (
        <DeleteQuestionModal
          questionId={question.id}
          onCancel={() => setOpenDeleteQuestionModal(false)}
          open={openDeleteQuestionModal}
        />
      )}
    </div>
  );
};

SingleQuestionForm.propTypes = {
  sectionId: PropTypes.number.isRequired,
  question: PropTypes.object,
  onCreated: PropTypes.func,
};

SingleQuestionForm.defaultProps = {
  question: null,
  onCreated: () => {},
};

const QuestionForm = ({ sectionId }) => {
  const { t } = useTranslation();

  const [showNewQuestionForm, setShowNewQuestionForm] = useState(false);
  const { data, isSuccess } = useGetQuestionsQuery({
    section: sectionId,
    pagination: "off",
  });

  return (
    isSuccess && (
      <div className="mt-3">
        {data
          .slice()
          .sort((a, b) => a.index - b.index)
          .map((question) => (
            <div key={question.id} className="mt-2">
              <SingleQuestionForm
                sectionId={sectionId}
                question={question}
              />
            </div>
          ))}
        {showNewQuestionForm && (
          <div className="mt-3">
            <SingleQuestionForm
              sectionId={sectionId}
              onCreated={() => setShowNewQuestionForm(false)}
            />
          </div>
        )}
        <div className="mt-3" style={{ textAlign: "right" }}>
          <a onClick={() => setShowNewQuestionForm(true)}>
            <PlusOutlined /> {t("addQuestion")}
          </a>
        </div>
      </div>
    )
  );
};

QuestionForm.propTypes = {
  sectionId: PropTypes.number.isRequired,
};

export default QuestionForm;
